<template>
  <v-row class="upsell-page">
    <!-- Config layout -->
    <MenuApps :menu="menu" :defaultActiveMenu="defaultActiveMenu" />
    <v-col class="upsell-page-container">
      <div class="upsell-page-content">
        <router-view />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MenuApps from '../components/MenuApps';

export default {
  components: {
    MenuApps,
  },
  data() {
    return {
      menu: [
        {
          title: 'Ali connector',
          icon: 'fas fa-home',
          name: 'website.apps.ali-connector.import',
          children: [
            {
              title: 'Import List',
              name: 'website.apps.ali-connector.import',
            },
          ],
        },
      ],
      defaultActiveMenu: 'website.apps.ali-connector.import',
    };
  },
};
</script>

<style lang="scss">
.upsell-page {
  height: 100% !important;
  .upsell-page-container {
    @include flex(center, flex-center);
    overflow-y: auto;
    padding-left: 260px;
    & .upsell-page-content {
      max-width: 1024px;
      min-width: 700px;
      margin-top: 40px;
    }
  }
}
</style>
